<template>
  <header class="topbar">
    <b-navbar type="dark" toggleable="md" class="top-navbar">
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
      <div class="navbar-header">
        <b-navbar-brand to="/">
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img src="/assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
            <!-- Light Logo icon -->
            <img src="/assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="ml-2" v-show="!isMobile">
            <!-- dark Logo text -->
            <img src="/assets/images/logo-text.png" alt="homepage" class="dark-logo" />
            <!-- Light Logo text -->
            <img src="/assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
          </span>
        </b-navbar-brand>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse">
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <b-navbar-nav class="mr-auto">
          <b-nav-item @click.prevent="toggleSidebar" link-classes="toggle-nav" v-wave>
            <i class="icon-menu"></i>
          </b-nav-item>
        </b-navbar-nav>
        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->
        <b-navbar-nav class="my-lg-0">
          <b-nav-item-dropdown v-wave class="u-pro" toggle-class="profile-pic">
            <template #button-content>
              <b-img width="30" rounded="circle" 
                :src="uploader(user.avatar)" 
              />
              <span class="mx-2">{{ user.fullName }}</span>
              <i class="fa fa-angle-down" />
            </template>
            <b-dropdown-item :to="{name:'BoAccountSetting'}"><i class="ti-settings mr-1"></i> Account Setting</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="doLogout"><i class="fa fa-power-off mr-1"></i> Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </b-navbar>
  </header>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
export default {
  extends: GlobalVue,
  methods: {
    toggleSidebar() {
      this.$store.commit('toggleBoSidebar')
    }
  }
}
</script>